<script setup>
import { WdsAppBanner } from '@wds/components';

// ### PROPS
const props = defineProps({
  page: {
    type: String,
    required: true,
  },
  linkPaths: {
    type: Object,
    required: true,
  },
  linkParams: {
    type: String,
    default: 'search',
  },
});

// ### CONSTANTS
const APP_BANNER_FEAT_TOGGLE = 'web_app_download_banner';
const APP_BANNER_COOKIE_NAME = 'appBannerDismiss';
const APP_BANNER_MAX_DISMISS = 7 * 86400;
const APP_BANNER_LINK = 'https://hostelworld.onelink.me/IdiK/vnn7oxyn';

// ### COMPOSABLES
const { isMobileOrTablet } = useDevice();
const runtimeConfig = useRuntimeConfig();
const envData = runtimeConfig?.public;

// ### STORES
const testingStore = useTestingStore();

// ### DATA
const appBannerEnabled = testingStore.hasEnabledFeature(APP_BANNER_FEAT_TOGGLE);
const appBannerVars = testingStore.getFeatureVariables(APP_BANNER_FEAT_TOGGLE);
const appBanner = ref({
  enabled: appBannerEnabled && (appBannerVars[props.page] || appBannerVars.staticpages[props.page]),
  show: false,
  vars: appBannerVars || {},
});
const appBannerDismissCookie = useCookie(APP_BANNER_COOKIE_NAME, {
  domain: envData?.COOKIE_DOMAIN,
  path: '/',
  sameSite: 'Strict',
  secure: true,
  watch: true,
});

// ### COMPUTED
const showAppDownloadBanner = computed(() => {
  return appBanner.value.enabled && isMobileOrTablet && appBanner.value.show;
});

const appDownloadTitle = computed(() => {
  return appBanner.value.vars?.message === 'b' ? 't_APPBANNERTITLEPRICING' : 't_APPBANNERTITLESOCIAL';
});

const appDownloadSubTitle = computed(() => {
  return appBanner.value.vars?.message === 'b' ? 't_APPBANNERSUBTITLEPRICING' : 't_APPBANNERSUBTITLESOCIAL';
});

// ### METHODS
const handleAppBannerDismiss = function () {
  appBannerDismissCookie.value = Math.floor(Date.now() / 1000);
  appBanner.value.show = false;
};

const handleAppBannerClick = function () {
  const appDeepLinkPath = props.linkPaths[appBanner.value.vars?.message] || props.linkPaths.a || APP_BANNER_LINK;
  window.location.href = `${appDeepLinkPath}?deep_link_sub10=${btoa(`hw://s/${props.linkParams}`)}`;
};

// ### MOUNTED
onMounted(() => {
  if (appBanner.value.enabled) {
    if (appBannerDismissCookie.value) {
      const timestamp = Math.floor(Date.now() / 1000);
      appBanner.value.show = timestamp - appBannerDismissCookie.value >= APP_BANNER_MAX_DISMISS;
    } else {
      appBanner.value.show = true;
    }
  }
});
</script>

<template>
  <WdsAppBanner
    v-if="showAppDownloadBanner"
    :title="$t(appDownloadTitle)"
    :subtitle="$t(appDownloadSubTitle)"
    :action-button="$t('t_APPBANNEROPEN').toUpperCase()"
    @dismiss-click="handleAppBannerDismiss"
    @banner-click="handleAppBannerClick"
  />
</template>
